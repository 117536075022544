@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  font-size: 10px;
  --black: #000;
  --white: #fff;
  --color-secondary-blue: #000428;
  --color-primary-blue: #004e92;
  --color-primary-orange: #f39200;
  --color-secondary-orange: #e94e1b;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Archivo", sans-serif;
}
html,
body {
  width: 100%;
  max-width: 100vw;
}
.box {
  width: 100%;
  max-width: 1420px;
  padding: 0 15rem;
  margin: 0 auto;
}
.box-lg {
  width: 100%;
  max-width: 2500px;
  padding: 0 3.5rem;
  margin: 0 auto;
}
a {
  text-decoration: underline !important;
}
img {
  object-fit: contain;
}
.blue-gradient-text {
  background: linear-gradient(90deg, #000428, #004e92);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-family: "Archivo", sans-serif;
}
.orange-gradient-text {
  background: linear-gradient(90deg, #f39200, #e94e1b);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-family: "Archivo", sans-serif;
}
.gradient-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(90deg, #000428, #004e92);
  border-radius: 0.75rem;
  padding: 1rem 2.5rem;
  color: var(--white);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  border: unset;
}
.gradient-orange-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-image: linear-gradient(90deg, #f39200, #e94e1b);
  border-radius: 0.5rem;
  height: 100%;
  padding: 1.65rem 2rem;
  color: var(--white);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  border: unset;
}
.homepage {
  width: 100%;
  overflow: hidden;
  max-width: 100vw;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 5rem 0;
  max-width: 705px;
  margin: 0 0 0 auto;
}
.header-content img {
  width: 300px;
}
.seperator {
  width: 100%;
  background-color: var(--color-primary-blue);
  height: 1px;
}

.hero-section {
  width: 100%;
  padding: 20rem 0;
}
.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  width: 100%;
  position: relative;
}
.blue-glow {
  width: 212px;
  height: 212px;
  background-color: var(--color-primary-blue);
  filter: blur(200px);
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 50%;
}
.blue-glow-small {
  width: 21px;
  height: 21px;
  background-image: linear-gradient(90deg, #000428, #004e92);
  position: absolute;
  top: -20%;
  left: 15%;
  border-radius: 50%;
}
.blue-glow-medium {
  width: 30px;
  height: 30px;
  background-image: linear-gradient(90deg, #000428, #004e92);
  position: absolute;
  bottom: 40%;
  right: 15%;
  border-radius: 50%;
}
.orange-glow-small {
  width: 21px;
  height: 21px;
  background-image: linear-gradient(90deg, #f39200, #e94e1b);
  position: absolute;
  bottom: 0;
  left: 25%;
  border-radius: 50%;
}
.orange-glow {
  width: 212px;
  height: 212px;
  background-color: var(--color-primary-orange);
  filter: blur(200px);
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}
.hero-content h1 {
  font-size: 6rem;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  width: 90%;
  line-height: 110%;
}
.hero-content p {
  font-size: 2.65rem;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
}
.hero-content img {
  position: absolute;
  right: 5%;
  top: -5%;
}

.getback-section {
  background-color: #f5f8fb;
  padding: 10rem 0;
  width: 100%;
}
.get-back-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.5rem;
}
.get-back-content h2 {
  font-size: 5.35rem;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  width: 90%;
  line-height: 110%;
}
.get-back-content p {
  font-size: 2.65rem;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
}
.get-back-content h3 {
  font-size: 2.65rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
}
.get-back-content h6 {
  font-size: 2.65rem;
  font-weight: 600;
  margin: 0;
  text-align: left;
}
.get-back-content h6 span a {
  text-decoration: underline;
}
.get-back-content ul {
  list-style: disc !important;
}
.get-back-content ul li {
  font-size: 2.15rem;
  font-weight: 500;
  margin: 0 0 1.5rem 2rem;
  list-style: disc !important;
}
.get-back-content h5 {
  font-size: 2.15rem;
  font-weight: 500;
  margin: 0;
}

.response-section {
  background-image: linear-gradient(90deg, #000428, #004e92);
  width: 100%;
  padding: 10rem 0;
}
.response-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7rem;
}
.response-content h2 {
  font-size: 5.35rem;
  font-weight: 800;
  text-align: center;
  margin: 0 auto;
  width: 90%;
  line-height: 120%;
  color: var(--white);
}
.response-content p {
  font-size: 2.25rem;
  font-weight: 500;
  text-align: center;
  margin: 0 auto 2rem auto;
  color: var(--white);
}
.response-content img {
  width: 100%;
  height: 100%;
}
.getback-section.gbs-1 h2 {
  margin: 0 auto 7rem auto;
}

footer {
  background-color: var(--white);
  padding: 10rem 0;
}
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content img {
  width: 300px;
}

@media only screen and (max-width: 850px) {
  .box {
    padding: 0 2rem;
  }
  .header-content img {
    width: 150px;
  }
  .header-content .gradient-btn {
    padding: 1rem 1.5rem;
    font-size: 1.45rem;
  }
  .header-content {
    padding: 2.5rem 0;
    max-width: 100%;
  }
  .hero-content h1 {
    font-size: 2.65rem;
    width: 100%;
  }
  .hero-section {
    padding: 10rem 0;
  }
  .blue-glow-small {
    top: -7%;
  }
  .hero-content img {
    right: -3%;
  }
  .hero-content p {
    font-size: 2rem;
  }
  .blue-glow-medium {
    display: none;
  }
  .orange-glow-small {
    left: 4%;
  }
  .get-back-content h2 {
    font-size: 2.45rem;
    width: 100%;
  }
  .get-back-content p {
    font-size: 2rem;
  }
  .get-back-content h6 {
    font-size: 2rem;
  }
  .get-back-content ul li {
    font-size: 2rem;
    line-height: 140%;
  }
  .get-back-content h5 {
    font-size: 2rem;
  }
  .response-content h2 {
    font-size: 2.45rem;
    width: 100%;
    line-height: 130%;
  }
  .response-content {
    gap: 3rem;
  }
  .response-content p {
    font-size: 2rem;
    margin: 0;
  }
  .get-back-content h3 {
    font-size: 2rem;
  }
  .getback-section.gbs-1 h2 {
    margin: 0 auto 3rem auto;
  }
  footer {
    padding: 5rem 0;
  }
  .footer-content img {
    width: 150px;
  }
}
